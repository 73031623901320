@import url("../../../variables.css");

.pricing-container {
    text-align: center;
    padding: 60px 20px;
    background: linear-gradient(to right, rgba(227, 227, 227, 0.2), rgba(14, 86, 153, 0.2));
    background-position: top;
}

.pricing-title, .pricing-subtitle {
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0px;
  margin-bottom: 10px;
}

.highlight {
    color: var(--blue);
}

.pricing-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 60px;
}

.pricing-card {
  background: var(--white);
  max-width: 390px;
  height: 838px;
  padding: 60px;
  border-radius: 24px;
  box-shadow: 0px 0px 24px 0px #292d3240;
  transition: transform 0.3s, background 0.3s, color 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.pricing-card:hover {
  transform: translateY(-10px);
  background: var(--blue);
  color: var(--white);
  box-shadow: 0px 26px 80px 0px #00000040;
}

.pricing-card:hover .pricing-header h3,
.pricing-card:hover .pricing-header h4,
.pricing-card:hover .pricing-features,
.pricing-card:hover .pricing-features li,
.pricing-card:hover .icon {
  color: var(--white);
}

.pricing-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pricing-header h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 32px;
  text-align: left;
  color: var(--black);
}

.pricing-header h3 span {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: var(--blue);
}

.pricing-card:hover .pricing-header h3 span {
  color: var(--white);
}

.pricing-header h4 {
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  font-weight: 600;
  margin: 0px;
  text-align: left;
  color: var(--black);
}

.pricing-features {
  list-style: none;
  padding: 0;
  text-align: left;
  margin: 32px 0;
}

.pricing-features li {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  color: var(--blue);
}

.icon {
  margin-right: 10px;
  color: var(--blue);
  flex-shrink: 0;
}

.pricing-button {
  width: 312px;
  height: 44px;
  padding: 6.4px 15px;
  gap: 10px;
  border-radius: 2px 0px 0px 0px;
  border: 3px solid var(--white);
  box-shadow: 0px 2px 0px 0px #0000000B;
  background: var(--blue);
  color: var(--white);
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin: auto;
  margin-bottom: 0px;
  display: none;
}

.pricing-card:hover .pricing-button {
  display: block;
}

@media (max-width: 768px) {
  .pricing-cards {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 390px) {
  .pricing-card{
    padding: 20px;
  }
}
