.recommendations .ant-collapse {
  background-color: var(--white);
}

.recommendations .ant-collapse-expand-icon .anticon-caret-down svg {
  color: var(--black) !important;
}

.recommendations .ant-collapse-item {
  box-shadow: 0px 2px 2px 0px #00000026;
  background-color: var(--prb-bg) !important;
}

.recommendations .ant-collapse-header {
  box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
  border-left: 4px solid var(--prb-border);
}

.recommendations .ant-collapse-header-text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: var(--robotto-text);
}

.recommendations .sol {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  color: var(--black);
}

.recommendations .ant-collapse-content-box {
  padding: 16px !important;
}
