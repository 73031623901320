@import url("../../../../variables.css");

.overview {
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.overview .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--gray);
}

@media screen and (max-width: 350px) {
  .overview .header {
    flex-direction: column;
  }
}

.overview .domain-url {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 200;
  color: var(--sidetext);
}

.overview p {
  font-family: Roboto;
  font-size: 16px;
  color: var(--robotto-title);
}

.overview .img-wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}

.overview .img-wrap img {
  width: 100px;
}

.overview .domain-name {
  font-family: Poppins;
  color: var(--black);
}

.overview .details {
  font-family: Poppins;
  color: var(--robotto-title);
}

.overview .ant-collapse-header {
  font-size: 18px;
  font-weight: bold;
  color: var(--robotto-title) !important;
}

.overview .ant-collapse {
  background-color: var(--bg-color);
  border-radius: 8px;
  border: 1px solid var(--gray);
}

.overview .ant-collapse-item {
  border-bottom: 1px solid var(--gray);
}

.overview .ant-collapse-content {
  border-color: var(--white) !important;
}

.overview .ant-collapse-content-box {
  background-color: var(--bg-color2);
  padding: 10px 24px;
}

.overview ul {
  list-style: none;
  padding: 0;
}

.overview li {
  background: var(--prb-bg);
  margin: 5px 0;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  color: var(--robotto-text) !important;
}

.overview li:hover {
  background: var(--prb-border);
}

.overview .icon {
  margin-right: 8px;
  color: #1890ff;
}

.overview .location {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.overview .list-item {
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: left;
  overflow-x: scroll;
}
