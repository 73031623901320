@import url("../../../variables.css");

.user .page-title {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;

  margin-bottom: 32px;
  color: var(--black);
}

.user .tabs-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 23px;
}

.user .tab {
  background-color: var(--white);
  padding: 16px;
  border-radius: 0px 8px 8px 0px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  border: 1px solid var(--gray);
  border-left: 4px solid;

  width: calc(25% - 15px);
}

@media screen and (max-width: 870px) {
  .user .tab {
    width: calc(50% - 10px);
  }
}

@media screen and (max-width: 400px) {
  .user .tab {
    width: calc(100%);
  }
}

.user .tab-title {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  color: var(--robotto-text);
}

.user .tab-val {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  color: var(--robotto-title);
}

.user .ant-progress-text {
  color: var(--black);
}

.user .section {
  background-color: var(--white);
  border: 1px solid var(--gray);
  border-radius: 8px;
  padding: 20px;
}

.user .section-title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: 16px 8px 8px;
  color: var(--robotto-text);
}

.user .ant-form-item {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
}

.user .ant-form-item-label label {
  color: var(--black) !important;
}

.user .ant-form-item-extra span {
  color: var(--robotto-title);
}

.user .ant-form-item .ant-typography {
  color: var(--robotto-text);
}

.user .ant-input-group-addon {
  background-color: var(--bg-color) !important;
  border-color: var(--gray) !important;
}

.user .ant-select {
  width: 100% !important;
  height: 40px;
}

.user .btn {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 370px) {
  .btn {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.user .ant-input {
  background-color: var(--bg-color) !important;
  color: var(--black) !important;
  border-color: var(--gray) !important;
}

.user .ant-select-selector {
  background-color: var(--bg-color) !important;
  color: var(--black) !important;
  border-color: var(--gray) !important;
}

.password-input,
.password-input .ant-input-status-error {
  background-color: var(--bg-color) !important;
  border: 1px solid var(--gray);
}