@import url("../../../../variables.css");

.domain-modal .ant-modal-title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.domain-modal .ant-form-item-required,
.domain-modal .ant-form-item {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}

.domain-modal .ant-upload {
  background-color: #d1e9ff;
}

.domain-modal .ant-input {
  background-color: var(--bg-color) !important;
  color: var(--black) !important;
  border-color: var(--gray) !important;
}

.domain-modal .ant-select-selector {
  background-color: var(--bg-color) !important;
  color: var(--black) !important;
  border-color: var(--gray) !important;
}

.domain-modal .ant-modal-content {
  background-color: var(--bg-color) !important;
}

.domain-modal .ant-modal-title {
  background-color: var(--bg-color) !important;
  color: var(--black) !important;
}

.domain-modal .ant-form-item-label label {
  color: var(--black) !important;
}

.domain-modal .ant-checkbox-wrapper span {
  color: var(--black) !important;
}
