:root {
  --blue: #50a5f1;
  --blue-dark: #073f73;
  --black: #000;
  --white: #fff;
  --sidetext: #324054;
  --gray: #d9d9d9;
  --light-gray: #4d4d4d;
  --bg-color: #f8f9fb;
  --bg-color2: #f3f9ff;
  --bg-selected: #e6f7ff;
  --robotto-text: #000000d9;
  --robotto-title: #00000073;
  --prb-border: #95acf1;
  --prb-bg: #eaeefa;
  --mthd-border: #d9d9d9;
  --mthd-bg: #1890ff1a;
  --sidebar-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --placeholder: #acacac;
}

.dark-mode {
  --blue: #073f73;
  --blue-dark: #50a5f1;
  --black: #fff;
  --white: #1c1c1c;
  --sidetext: #c3d4e4;
  --gray: #3a3a3a;
  --light-gray: #d5d5d5;
  --bg-color: #2e2e2e;
  --bg-color2: #1e1e1e;
  --bg-selected: #3a3a3a;
  --robotto-text: #ffffffd9;
  --robotto-title: #ffffff73;
  --prb-border: #6b9dd9;
  --prb-bg: #2a3b55;
  --mthd-border: #333333;
  --mthd-bg: #0c385e;
  --sidebar-shadow: rgba(15, 16, 17, 0.2) 0px 8px 24px;
  --scrollbar-bg: #2e2e2e;
  --scrollbar-thumb-bg: #1c1c1c;
  --scrollbar-box-shadow: rgba(0, 0, 0, 0.75);
  --placeholder: #bdbdbda4;
}
