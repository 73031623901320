@import url("../../../variables.css");

.signup{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
    
.signup .signup-card{
    width: 865px;
    margin: 0px 25px;
    max-height: 90vh;
}
    
.signup .title{
    display: flex;
    flex-direction: column;
    gap: 32px;
    color: #000;
}

.signup .title h1{
    margin: 0%;
    font-family: Poppins;
    font-size: 36px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
}

.signup .title p{
    margin: 0%;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
}

.signup .form{
    max-width: 556px;
    margin: auto;
    margin-top: 64px;
    gap: 32px;
}

.signup .signup-form-button{
    width: 100%;
    height: 40px;

    font-family: Poppins !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    text-align: center !important;
    margin-top: 25px;
    box-shadow: none;
    color: #fff;
}

.signup .name-label,
.password-label,
.email-label {
  font-family: Poppins !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #000;
}

.signup .login-link {
  font-family: Poppins !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 22px !important;
}

.signup .underline{
    text-decoration: underline;
    color: var(--blue);
    cursor: pointer;
}


