@import url("../../../variables.css");

.users {
  min-height: 100vh;
  height: 100%;
}

.users .page-title {
  padding: 16px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: var(--robotto-text);

  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;

  text-align: left;
}

.users .table-container {
  overflow-x: auto;
}

.users .ant-table-thead .ant-table-cell {
  background-color: var(--bg-color2) !important;
  color: var(--black) !important;
}

.users .ant-table-tbody > tr:nth-child(odd) {
  background-color: var(--white);
  color: var(--black) !important;
}

.users .ant-table-tbody > tr:nth-child(even) {
  background-color: var(--bg-color2) !important;
  color: var(--black) !important;
}

.users .ant-table-tbody > tr:nth-child(odd):hover {
  color: #000 !important;
}

.users .ant-table-tbody > tr:nth-child(even):hover {
  color: #000 !important;
}

.users .ant-pagination-item-link,
.users .ant-pagination-item a {
  color: var(--black) !important;
}

.users .ant-pagination-item-active a {
  color: var(--blue) !important;
}
