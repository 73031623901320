.records {
  overflow-x: auto;
  padding: 5px;
  border-radius: 12px;
  background-color: var(--bg-color2);
  border: 1px solid #ccc;
}

.records-wrap .copy-btn {
  display: flex;
  align-items: flex-end;
  justify-content: end;
  padding-top: 10px;
  padding-bottom: 10px;
}

.records .ant-table-thead .ant-table-cell {
  background-color: var(--bg-color2) !important;
  color: var(--black) !important;
}

.records .ant-table-tbody > tr:nth-child(odd) {
  background-color: var(--white);
  color: var(--black) !important;
}

.records .ant-table-tbody > tr:nth-child(even) {
  background-color: var(--bg-color2) !important;
  color: var(--black) !important;
}

.records .ant-table-tbody > tr:nth-child(odd):hover {
  color: #000 !important;
}

.records .ant-table-tbody > tr:nth-child(even):hover {
  color: #000 !important;
}

.records .ant-pagination-item-link,
.records .ant-pagination-item a {
  color: var(--black) !important;
}

.records .ant-pagination-item-active a {
  color: var(--blue) !important;
}
