@import url("../../../variables.css");

.verification {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .verification .verification-card {
    width: 865px;
    margin: 0px 25px;
  }
  
  .verification .title {
    display: flex;
    flex-direction: column;
    gap: 32px;
    color: #000;
  }
  
  .verification .title h1 {
    margin: 0px;
    font-family: Poppins;
    font-size: 36px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
  }
  
  .verification .title p {
    margin: 0px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
  }
  
  .verification .form {
    max-width: 556px;
    margin: auto;
    margin-top: 40px;
    gap: 32px;
  }
  
  .verification .verification-form-button {
    width: 100%;
    height: 40px;
    font-family: Poppins !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    text-align: center !important;
    margin-top: 25px;
    box-shadow: none;
    color: #fff;
  }
  
  .verification .login-link {
    font-family: Poppins !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 22px !important;
  }
  
  .verification .underline {
    text-decoration: underline;
    color: var(--blue);
    cursor: pointer;
  }
  
  .verification .verification-code-input {
    display: flex;
    justify-content: center;
    gap: 32px;
  }

  .verification .ant-form-item-explain-error {
    text-align: center;
  }
  
  .verification .verification-code-input-item {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    border: 1px solid #292D3240;
    text-align: center;
    font-size: 24px;
  }

  @media (max-width: 768px) {
    .verification .verification-code-input-item {
      width: 50px;
      height: 50px;
      font-size: 20px;
    }
    .verification .verification-code-input{
        gap: 28px;
    }
  }
  
  @media (max-width: 370px) {
    .verification .verification-code-input-item {
      width: 40px;
      height: 40px;
      font-size: 16px;
    }
    .verification .verification-code-input{
        gap: 14px;
    }
  }
  