@import url("../../variables.css");

.navbar {
  width: 100%;
  height: 60px;
  background-color: var(--blue);
  padding: 10px 28px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navbar .profile-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.navbar .profile-container:hover {
  cursor: pointer;
}

.navbar .profile-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: right;
}

.navbar .name {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: right;

  color: #fff;
}

.navbar .email {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: right;
  color: var(--light-gray);
}

.navbar .ant-switch {
  background-color: var(--bg-color2) !important;
}

.navbar .ant-space-item svg {
  color: var(--black);
}

.navbar .profile-photo {
  width: 40px;
  border-radius: 40px;
  border: 1px solid #777777;
}