@import url("../../../variables.css");

.lookup {
  height: 100%;
  min-height: 100vh;
}

.lookup .page-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 34px;
}

@media screen and (max-width: 560px) {
  .lookup .page-header {
    flex-direction: column;
    gap: 16px;
  }
}

.lookup .page-title {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: var(--black);
}

.lookup .right {
  display: flex;
  gap: 10px;
}

@media screen and (max-width: 320px) {
  .lookup .right {
    flex-direction: column;
  }
}

.lookup .page-header .ant-input-affix-wrapper {
  max-width: 200px !important;
  padding: 6px 12px !important;
}

.lookup .ant-btn-default {
  color: var(--blue) !important;
  border-color: var(--blue) !important;
}

.modal-debug .ant-modal-footer {
  display: none !important;
}

.modal-debug .ant-modal-content {
  padding: 0px;
  border-radius: 12px;
  overflow-y: auto;
}

.lookup .search-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70%;
  font-size: 1.5rem;
  color: #888;
  text-align: center;
}
