 @import url("../../../variables.css");

  .api .title {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 44px;
    color: var(--black);
  }
  
  .api .description {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 30px;
    color: var(--black);
  }
  
  .api .ant-collapse {
    background-color: var(--white);
  }
  
  .api .ant-collapse-expand-icon .anticon-caret-down svg {
    color: var(--black) !important;
  }
  
  .api .ant-collapse-item {
    box-shadow: 0px 2px 2px 0px #00000026;
    background-color: var(--mthd-bg) !important;
  }
  
  .api .ant-collapse-header {
    box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
    border-left: 4px solid var(--mthd-border);
  }
  
  .api .ant-collapse-header-text {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: var(--robotto-text);
  }
  
  .api .sol {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--black);
  }
  
  .api .methods .ant-collapse-content-box {
    padding: 16px !important;
  }
  
  .api .key {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .api-key-input {
    width: 100%;
  }
  
  .api .key-input {
    display: flex;
    align-items: center;
  }
  
  .api .key-input .ant-input {
    flex: 1;
  }
  
  .api .key-input .ant-btn {
    margin-left: 8px;
  }

  .api .key-button {
    align-self: flex-end;
  }

  .api .ant-btn-primary.ant-btn-dangerous{
    border-radius: 4px;
  }
  
  .api-key-input input {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    color: var(--black);
  }

  .api .ant-input-affix-wrapper{
    background-color: var(--bg-color) !important;
    color: var(--black) !important;
    border-color: var(--gray);
  }

  .api .ant-input-group-addon{
    background-color: var(--bg-color) !important;
    color: var(--black) !important;
    border-color: var(--gray);
  }

  .api .usage{
    margin-top: 20px;
    margin-bottom: 24px;
  }

  .api .usage-label{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 8px;
    color: var(--black);
  }

  .api .text-field{
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 20px;
    border: 1px solid;
    border-color: var(--gray);
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400px;
    color: var(--black);
  }

  .api .request-button{
    width: fit-content;
    align-self: flex-end;
    border-radius: 4px;
    white-space: normal;
    height: auto;
  }


  