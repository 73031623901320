@import url("../../../variables.css");

.settings .page-title {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  margin-bottom: 32px;
  color: var(--black);
}

.settings .settings-section {
  background-color: var(--white);
  border: 1px solid var(--gray);
  border-radius: 8px;
  padding: 20px;
}

.settings .settings-section-title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: 16px 8px 8px;
  color: var(--robotto-text);
}

.settings .ant-form-item {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
}

.settings .ant-form-item-label label {
  color: var(--black) !important;
}

.settings .ant-form-item-extra span {
  color: var(--robotto-title);
}

.ant-form-item .ant-typography {
  color: var(--robotto-text);
}

.settings-btn {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 370px) {
  .settings-btn {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.settings .ant-input {
  background-color: var(--bg-color) !important;
  color: var(--black) !important;
  border-color: var(--gray) !important;
}

.settings .ant-select-selector {
  background-color: var(--bg-color) !important;
  color: var(--black) !important;
  border-color: var(--gray) !important;
}
