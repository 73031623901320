@import url("../../../variables.css");

.verifications {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.verifications .page-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: var(--black);
}

.verifications .status-title {
  padding: 4px 8px;
  border-radius: 4px;
  color: var(--white);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}

.verifications .status-enabled {
  background-color: #008000;
}

.verifications .status-disabled {
  background-color: #ff0000;
}

.verifications .verifications-section {
  display: flex;
  flex-direction: column;
}

.verifications .form-item {
  position: relative;
  margin-bottom: 24px;
}

.verifications .form-item-text {
  width: 100%;
  padding: 20px;
  border: 1px solid var(--gray);
  border-radius: 8px;
  color: var(--black);
}

.verifications .form-item-text-two {
  margin-bottom: 24px;
  display: block;
}

.verifications .form-item-text-blue {
  margin-bottom: 32px;
  color: var(--blue);
  display: block;
}

.verifications .status-enabled-inline {
  color: #008000;
}

.verifications .status-disabled-inline {
  color: #ff0000;
}

.verifications .form-label {
  margin-bottom: 8px;
  display: block;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
}

.verifications .textarea1 {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: var(--black);
}

.verifications .radio-group {
  padding-bottom: 24px;
}

.verifications .fontcolor {
  color: var(--black);
}

@media screen and (max-width: 370px) {
  .verifications .verifications-btn {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
