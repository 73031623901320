@import url("../../../../variables.css");

.qr-modal .ant-modal-title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.qr-modal .ant-form-item-required,
.qr-modal .ant-form-item {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}

.qr-modal .ant-upload {
  background-color: #d1e9ff;
}

.qr-modal .ant-input {
  background-color: var(--bg-color) !important;
  color: var(--black) !important;
  border-color: var(--gray) !important;
}

.qr-modal .ant-select-selector {
  background-color: var(--bg-color) !important;
  color: var(--black) !important;
  border-color: var(--gray) !important;
}

.qr-modal .ant-modal-content {
  background-color: var(--bg-color) !important;
}

.qr-modal .ant-modal-title {
  background-color: var(--bg-color) !important;
  color: var(--black) !important;
}

.qr-modal .ant-form-item-label label {
  color: var(--black) !important;
}

.qr-modal .ant-checkbox-wrapper span {
  color: var(--black) !important;
}

.qr-modal .qr-modal-code-input {
  display: flex;
  justify-content: center;
  gap: 32px;
}

.qr-modal .ant-form-item-explain-error {
  text-align: center;
}

.qr-modal .qr-modal-code-input-item {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  border: 1px solid #292D3240;
  text-align: center;
  font-size: 24px;
}

@media (max-width: 768px) {
  .qr-modal .qr-modal-code-input-item {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }
  .qr-modal .qr-modal-code-input{
      gap: 20px;
  }
}

@media (max-width: 370px) {
  .qr-modal .qr-modal-code-input-item {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
  .qr-modal .qr-modal-code-input{
      gap: 14px;
  }
}

.qr-modal .verification-form{
  margin-top: 10px;
  margin-bottom: 40px;
}

.qr-modal .step-text-box {
  border-radius: 8px;
  color: var(--black);
}

.qr-modal .step-text {
  width: 80%;
}

.qr-modal .qr-box{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.qr-modal .qr-image{
  width: 40%;
}