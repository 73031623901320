@import url("../../../variables.css");

.subs {
  min-height: 100vh;
  height: 100%;
}

.subs .page-title {
  padding: 16px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: var(--robotto-text);

  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;

  text-align: left;
}

@media screen and (max-width: 340px) {
  .subs .page-title {
    flex-direction: column;
    gap: 16px;
  }
}

.subs .table-container {
  overflow-x: auto;
}

.subs .ant-table-thead .ant-table-cell {
  background-color: var(--bg-color2) !important;
  color: var(--black) !important;
}

.subs .ant-table-tbody > tr:nth-child(odd) {
  background-color: var(--white);
  color: var(--black) !important;
}

.subs .ant-table-tbody > tr:nth-child(even) {
  background-color: var(--bg-color2) !important;
  color: var(--black) !important;
}

.subs .ant-table-tbody > tr:nth-child(odd):hover {
  color: #000 !important;
}

.subs .ant-table-tbody > tr:nth-child(even):hover {
  color: #000 !important;
}

.subs .status-active {
  padding: 4px 20px 4px 20px;

  border-radius: 2000px;

  border: 1px solid #52c41a;
  text-transform: capitalize;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  color: #52c41a;
}

.subs .status-active:hover {
  background-color: #53c41a1f;
}

.subs .status-inactive {
  padding: 4px 20px 4px 20px;

  border-radius: 2000px;

  border: 1px solid #faad14;
  text-transform: capitalize;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  color: #faad14;
}

.subs .status-inactive:hover {
  background-color: #faad141f;
}
