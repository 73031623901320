@import url("../../../variables.css");

.domain-details {
  min-height: 100vh;
  overflow-y: auto;
}

.domain-details .page-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 34px;
}

@media screen and (max-width: 560px) {
  .domain-details .page-header {
    flex-direction: column;
    gap: 16px;
  }
}

.domain-details .domain-name {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: var(--black);
}

.domain-details .domain-url {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: var(--light-gray);
}

.domain-details .right {
  display: flex;
  gap: 16px;
}

@media screen and (max-width: 340px) {
  .domain-details .right {
    flex-direction: column;
  }
}

.domain-details .ant-btn-default {
  color: var(--blue) !important;
  border-color: var(--blue) !important;
}

.domain-details .ant-tabs-tab-btn {
  color: var(--black) !important;
}

.domain-details .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--blue) !important;
}
