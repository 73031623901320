@import url("../../variables.css");

.sidebar {
  box-shadow: var(--sidebar-shadow);

  z-index: 1000;
}

.sidebar .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar .ant-layout-sider {
  margin: 0px auto;
}

.sidebar .logo-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
}

.sidebar .ant-layout-sider-children {
  background-color: var(--white) !important;
}

.sidebar .ant-menu {
  overflow-y: auto;
  background-color: var(--white) !important;
  border: none !important;
}

.sidebar .ant-menu-item,
.sidebar .ant-menu-submenu-title {
  height: fit-content !important;
  display: flex;
  gap: 16px;
  align-items: center;
}

.sidebar .ant-menu-title-content {
  display: flex;
  font-family: "Poppins";
  font-size: 18px !important;
  color: var(--blue);

  line-height: 27px;
  text-align: left;
}

.sidebar .ant-menu-title-content a {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  height: fit-content;
}

.sidebar .menu-item-label {
  line-height: 40px;
  font-size: 14px;
  color: var(--sidetext);
}

.sidebar .ant-menu-item-selected .ant-menu-title-content {
  color: var(--blue);
  font-weight: 600;
}

.sidebar .ant-menu-item-selected {
  background-color: var(--bg-selected) !important;
}

.sidebar .ant-menu-submenu-arrow {
  color: var(--blue);
}

.sidebar .ant-layout-sider-zero-width-trigger {
  margin-top: 10px;
  top: 0;
  background-color: var(--blue) !important;
  border: none !important;
}

.sidebar .ant-layout-sider-zero-width-trigger span {
  color: var(--white);
}

.sidebar .sidebar-footer {
  width: 100%;
  padding: 20px;
  color: var(--black);
}

.sidebar .ant-btn {
  width: 100% !important;
}

.sidebar ::-webkit-scrollbar {
  width: 0px;
}
