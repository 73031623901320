@import url("../../../variables.css");

.domains {
  min-height: 100vh;
  height: 100%;
}

.domains .domain-wrapper {
  box-shadow: 0px 2px 2px 0px #00000026;
  background-color: var(--white);
}

.domains .page-title {
  padding: 16px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: var(--robotto-text);

  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;

  text-align: left;
}

@media screen and (max-width: 450px) {
  .domains .page-title {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}

.domains .table-container {
  overflow-x: auto;
}

.domains .ant-table-thead .ant-table-cell {
  background-color: var(--bg-color2) !important;
  color: var(--black) !important;
}

.domains .ant-table-tbody > tr:nth-child(odd) {
  background-color: var(--white);
  color: var(--black) !important;
}

.domains .ant-table-tbody .ant-table-column-sort {
  background-color: var(--white);
  color: var(--black) !important;
}

.domains .ant-table-tbody > tr:nth-child(even),
.domains .ant-table-tbody > tr:nth-child(even) .ant-table-column-sort {
  background-color: var(--bg-color2) !important;
  color: var(--black) !important;
}

.domains .ant-table-tbody > tr:nth-child(odd):hover {
  color: #000 !important;
}

.domains .ant-table-tbody > tr:nth-child(even):hover {
  color: #000 !important;
}

.domains .domain-name {
  font-weight: bold;
  color: var(--light-gray);
  font-size: 16px;
  text-transform: uppercase;
}

.domains .domain-name:hover {
  text-decoration: underline;
}

.domains .ant-pagination-item-link,
.domains .ant-pagination-item a {
  color: var(--black) !important;
}

.domains .ant-pagination-item-active a {
  color: var(--blue) !important;
}
