@import url('../../../variables.css');

.login{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
    
.login .login-card{
    width: 865px;
    margin: 0px 25px;
    max-height: 90vh;
}
    
.login .title{
    display: flex;
    flex-direction: column;
    gap: 32px;
    color: #000;

}

.login .title h1 {
  margin: 0%;
  font-family: Poppins;
  font-size: 36px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
}

.login .title p {
  margin: 0%;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}

.login .form {
  max-width: 556px;
  margin: auto;
  margin-top: 64px;
  gap: 32px;
}

.login .login-form-button {
  width: 100%;
  height: 40px;
    font-family: Poppins !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    text-align: center !important;
    color: #fff;
    box-shadow: none;
}

.login .username-label,
.password-label {
  font-family: Poppins !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #000;
}

.login .forgot-password,
.remember-me {
  font-family: Poppins !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 22px !important;
}

.login .signup-link {
  font-family: Poppins !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 22px !important;
}


.login .underline{
    text-decoration: underline;
    color: var(--blue);
    cursor: pointer;
}
