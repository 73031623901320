@import url("../../../../variables.css");

.debug-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
  background-color: var(--bg-color2);
  border-radius: 12px;
  border: 1px solid #ccc;
  min-height: 550px;
}

.debug-container .empty-container {
  padding: 175px 0px;
}

.debug-container .have-ques {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: var(--gray);
}

.debug-container .message-list {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 16px;
  background: none;
  padding: 8px;
  border-radius: 4px;
  border: none;
}

.debug-container .message-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.debug-container .message-item.user .message-sender {
  color: var(--black);
}

.debug-container .message-item.ai .message-sender {
  color: var(--blue);
}

.debug-container .ai {
  align-items: flex-start;
}

.debug-container .user {
  align-items: flex-end;
}

.debug-container .user .message-text {
  background-color: var(--blue);
  color: white;
}

.debug-container .ai .message-text {
  background-color: var(--sidetext);
  color: var(--white);
}

.debug-container .message-sender {
  font-weight: bold;
  margin-bottom: 4px;
}

.debug-container .message-text {
  background: #f0f0f0;
  padding: 8px;
  border-radius: 4px;
}

.debug-container .message-input {
  display: flex;
  border-radius: 8px;
}

.debug-container .ant-input-affix-wrapper {
  background-color: var(--bg-color);
  color: var(--black);
}
