@import url("../../variables.css");

@media (min-width: 768px) {
  .app-layout .site-layout {
    margin-left: 250px;
  }
}

.app-layout .content {
  margin: 0px;
  padding: 32px;
  background-color: var(--bg-color);
}

.app-layout .ant-layout {
  background-color: var(--white) !important;
}
