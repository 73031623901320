@import url("./variables.css");

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background: var(--scrollbar-bg);
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-bg);
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px var(--scrollbar-box-shadow);
}

::-webkit-scrollbar-corner {
  background: var(--scrollbar-bg);
}

.ant-input::placeholder {
  color: var(--placeholder) !important;
}

.ant-empty-description {
  color: var(--black) !important;
}
