@import url("../../../variables.css");

.dashboard {
  min-height: 100vh;
  height: 100%;
}

.dashboard .page-title {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  margin-bottom: 32px;
  color: var(--black);
}

.dashboard .wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.dashboard .row {
  width: 100%;
  display: flex;
  gap: 44px;
}

@media screen and (max-width: 890px) {
  .dashboard .row {
    flex-direction: column;
    gap: 28px;
  }
}

.dashboard .box {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 0px 20px 20px;

  box-shadow: 0px 0px 24px 0px #00000040;
  border-radius: 24px;
}

.dashboard .domain {
  background: linear-gradient(99.64deg, #1890ff 1.16%, #0e5699 73.06%);
}
.dashboard .email {
  background: linear-gradient(99.72deg, #22b75d 18.57%, #0f5129 82.51%);
}
.dashboard .scan {
  background: linear-gradient(99.99deg, #db1a3d 26.47%, #750e21 86.61%);
}
.dashboard .ai {
  background: linear-gradient(100.57deg, #7b61ff 21.1%, #4a3a99 78.49%);
}

.dashboard .left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard .box-title {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  color: #fff;
}

.dashboard .box-value {
  font-family: Poppins;
  font-size: 36px;
  font-weight: 600;
  text-align: left;
  color: #fff;
}

.dashboard .right img {
  height: 140px;
}

.dashboard .separator {
  width: 100%;
  height: 1px;

  margin: 50px 0px 50px 0px;

  border: 0.5px solid var(--black);
}

.dashboard .ant-collapse {
  background-color: var(--white);
}

.dashboard .ant-collapse-expand-icon .anticon-caret-down svg {
  color: var(--black) !important;
}

.dashboard .ant-collapse-item {
  box-shadow: 0px 2px 2px 0px #00000026;
  background-color: var(--white) !important;
}

.dashboard .ant-collapse-header {
  box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
  border-left: 4px solid #db1a3d;
}

.dashboard .email-collapse .ant-collapse-header {
  border-left: 4px solid #22b75d;
}

.dashboard .ant-collapse-header-text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: var(--robotto-text);
}

.dashboard .ant-collapse-content-box {
  padding: 16px !important;
}

.dashboard .analytics {
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 500px) {
  .dashboard .analytics {
    flex-direction: column;
    gap: 20px;
  }

  .dashboard .right img {
    height: 80px;
  }

  .dashboard .left .box-title {
    font-size: 16px;
  }

  .dashboard .left .box-value {
    font-size: 20px;
  }
}

.dashboard .analytic {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.dashboard .anal-title {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: var(--robotto-title);
}

.dashboard .anal-value {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;

  color: var(--robotto-text);
}

.dashboard .anal-change {
  display: flex;
  gap: 10px;

  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--robotto-text);
}
